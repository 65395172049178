import React, { FC } from 'react'
import styled from 'styled-components'
import { Box, Container, Link, Text, Stack, SimpleGrid } from '@chakra-ui/react'
import { SecondaryButton } from 'src/uikit'

const Icon = styled.span`
  & svg {
    display: inline-block;
    margin: auto;
  }
`

const InfoBox: FC<BoxGlobal.InfoBox> = ({ title, description, action, actionTitle, href, icon }) => {
  return (
    <Box bg="primaryGreen.50">
      <Container as={Stack} maxW="100%" py={10} textAlign="center" p="l">
        <SimpleGrid
          templateColumns={{ base: '1fr', sm: '1fr', md: '1fr 1.1fr 1.1fr' }}
          marginY="xxl"
          spacing={{ base: 'l', sm: 'l', md: 'xl' }}
        >
          <Box textAlign={{ base: 'center', sm: 'center', md: 'right' }}>
            <Icon>{icon}</Icon>
          </Box>
          <Box>
            <Text
              color="baseDark"
              fontFamily="Montserrat-SemiBold"
              fontSize="1.375rem"
              letterSpacing="0.6px"
              lineHeight="28.6px"
              whiteSpace="pre-wrap"
              marginBottom="xs"
              textAlign={{ base: 'center', sm: 'center', md: 'left' }}
            >
              {title}
            </Text>
            <Text
              color="baseDark"
              fontFamily="Montserrat-Regular"
              fontSize="1rem"
              letterSpacing="0.2px"
              lineHeight="20.8px"
              whiteSpace="pre-wrap"
              textAlign={{ base: 'center', sm: 'center', md: 'left' }}
            >
              {description}
            </Text>
          </Box>
          <Box my="auto" textAlign={{ base: 'center', sm: 'center', md: 'left' }}>
            <Link href={href}>
              <SecondaryButton
                title={actionTitle}
                action={action}
                width="12.438rem"
                textTransform="uppercase"
                display="inline"
              />
            </Link>
          </Box>
        </SimpleGrid>
      </Container>
    </Box>
  )
}

export default InfoBox
